<template>
  <div>
    <v-list dense>
      <v-list-item>
        <v-list-item-content>
          <v-text-field placeholder="Search for Product Skeletons" outlined hide-details single-line dense
            v-model="search"></v-text-field>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-for="(prod, index) in prods" :key="index">
        <v-list-item-content>
          <v-list-item-title>
            <v-chip class="mr-2">{{ prod.skuNumber }}</v-chip>{{ prod.name }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn color="primary" small @click="selectProductSkeleton(prod)" v-show="!isSelected(prod)">
            <v-icon class="mr-2">mdi-plus</v-icon>Add Link</v-btn>
          <v-btn v-show="isSelected(prod)" color="success" small @click="deselectProductSkeleton(prod)">
            <v-icon class="mr-2"> mdi-minus </v-icon>
            Remove Link
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-if="prods.length === 0">
        <v-list-item-title> No Linked Product Skeletons </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="py-0">
          <v-pagination :length="totalPages" v-model="pageNumber">
          </v-pagination>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  props: {
    companyId: {
      type: String,
      default: "",
      //only used for custom product skeletons
    },
    ProductSkeletonId: {
      type: String,
      default: "",
    },
    defaultSelectedProductSkeletons: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    totalPages() {
      if (this.totalProdCount > 0) {
        return Math.ceil(this.totalProdCount / this.pageSize);
      } else {
        return 0;
      }
    },
  },
  created() {
    this.getProds();
  },
  watch: {
    search(newValue, oldValue) {
      clearTimeout(this.searchDebounceId);

      this.searchDebounceId = setTimeout(() => {
        this.pageNumber = 1;
        this.getProds();
      }, 200);
    },
    pageNumber(newValue, oldValue) {
      this.getProds();
    },
    defaultSelectedProductSkeletons: {
      immediate: true,
      handler() {
        if (!this.hasLoadedOnce) {
          console.log("anus");
          this.getProductSkeletonLinkedProductSkeletons();
          this.hasLoadedOnce = true;
        }
      },
    },
  },
  methods: {
    getProductSkeletonLinkedProductSkeletons() {
      for (const linkedProd of this.defaultSelectedProductSkeletons) {
        if (!this.isSelected(linkedProd)) {
          this.selectProductSkeleton(linkedProd);
        }
      }
    },
    isSelected(prod) {
      return this.selectedProductSkeletons.some((x) => x == prod.id);
    },
    getProds() {
      this.$courier.ProductSkeletons.getData(
        this.pageNumber,
        this.pageSize,
        this.search,
        null,
        null,
        [{ key: 'OnlyShowGlobal', value: 'true' }]
      ).then((prodResp) => {
        this.prods = prodResp.payload;
        if (this.prods && this.prods.length > 0) {
          this.prods = this.prods.filter(
            (x) => x.id !== this.ProductSkeletonId
          );
        }
        this.totalProdCount = prodResp.totalCount;
      });
    },
    selectProductSkeleton(prod) {
      this.selectedProductSkeletons.push(prod.id);
      this.$emit(
        "update:defaultSelectedProductSkeletons",
        this.selectedProductSkeletons
      );
    },
    deselectProductSkeleton(prod) {
      this.selectedProductSkeletons = this.selectedProductSkeletons.filter(
        (x) => x != prod.id
      );
      this.$emit(
        "update:defaultSelectedProductSkeletons",
        this.selectedProductSkeletons
      );
    },
  },
  data() {
    return {
      hasLoadedOnce: false,
      isLoading: false,
      searchDebounceId: "",
      prods: [],
      pageNumber: 1,
      pageSize: 10,
      search: "",
      totalProdCount: 0,
      selectedProductSkeletons: [],
      linkedSkeletonCount: 0,
      globalSkeletonCount: 0,
    };
  },
};
</script>

<style>

</style>