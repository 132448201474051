<template>
  <div>
    <v-row v-if="!hideSwitch">
      <v-col>
        <v-switch
          label="Use Price Rules"
          v-model="hasPriceRules2"
          @click="$emit('update:hasPriceRules', hasPriceRules2)"
        >
        </v-switch>
      </v-col>
    </v-row>
    <v-expansion-panels flat v-model="hasPriceRule">
      <v-expansion-panel class="transparent">
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="4">
              <v-text-field
                label="If there are more than"
                type="number"
                v-model="newPriceRule.targetVariableOrProperty"
              >
              </v-text-field>
              <v-text-field
                label="The price will be"
                v-model="newPriceRule.pricePerUnit"
                type="number"
                prepend-icon="mdi-currency-gbp"
              >
              </v-text-field>
              <v-btn color="info" @click="addPriceRule">
                Add Price Rule
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="8">
              <v-simple-table class="transparent">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Minimum {{ unitCharged }} Count</th>
                      <th class="text-left">Unit Price</th>
                      <th>Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in priceRules2" :key="index">
                      <td>
                        If there are
                        {{ item.targetVariableOrProperty }}
                        {{ unitCharged }}s
                      </td>
                      <td>It will cost £{{ item.pricePerUnit }} each</td>
                      <td>
                        <v-btn
                          color="danger"
                          text
                          x-small
                          @click="removePriceRule(item)"
                          >Delete</v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  props: {
    hasPriceRules: {
      type: Boolean,
      default: false
    },
    unitCharged: {
      type: String,
      default: ""
    },
    priceRules: {
      type: Array,
      default: () => []
    },
    hideSwitch: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasPriceRule() {
      return this.hasPriceRules2 ? 0 : null;
    }
  },
  watch: {
    hasPriceRules: {
      immediate: true,
      handler(n, o) {
        this.priceRules2 = JSON.parse(JSON.stringify(this.priceRules));
        this.hasPriceRules2 = this.hasPriceRules;

        this.newPriceRule = {};
      }
    },
    priceRules(){
      this.priceRules2 = JSON.parse(JSON.stringify(this.priceRules));
    }
  },
  methods: {
    removePriceRule(priceRule) {
      this.priceRules2 = this.priceRules2.filter(x => x != priceRule);

      this.$emit("update:priceRules", this.priceRules2);
    },
    addPriceRule() {
      var pRule = JSON.parse(JSON.stringify(this.newPriceRule));
      this.priceRules2.push(pRule);
      this.$emit("update:priceRules", this.priceRules2);
      this.newPriceRule.targetVariableOrProperty = 0;
      this.newPriceRule.pricePerUnit = 0;
    }
  },
  data: () => ({
    hasPriceRules2: false,
    priceRules2: [],
    newPriceRule: {
      sourceProperty: "self",
      operator: "greaterthan",
      targetVariableOrProperty: 0,
      pricePerUnit: 0
    }
  })
};
</script>

<style></style>
