<template>
  <div>
    <v-select
      :items="['Support', 'Citrix', 'Development']"
      label="Division"
      v-model="div"
      @input="$emit('update:division', div)"
    >
    </v-select>
    <v-text-field
      label="SKUNumber"
      v-model="sku"
      @keyup="$emit('update:skuNumber', sku)"
    ></v-text-field>
    <!-- <v-text-field
      label="Supplier"
      v-model="supp"
      @keyup="$emit('update:supplier', supp)"
    ></v-text-field> -->
    <v-select
      :items="['Coffee Cup', 'Pax8']"
      label="Supplier"
      v-model="supp"
      @input="$emit('update:supplier', supp)"
    >
    </v-select>
    <v-text-field
      label="Name"
      v-model="nam"
      @keyup="$emit('update:name', nam)"
    ></v-text-field>
    <trev-xero-tenant-account-code-search
      :accountCodeId.sync="accCodeId"
      :tenantId.sync="tenantId"
    ></trev-xero-tenant-account-code-search>
    <v-select
      :items="resourceTypes"
      label="Product Resource Type"
      v-model="resType"
      @input="$emit('update:resourceType', resType)"
    >
    </v-select>
    <v-textarea
      label="Description"
      v-model="desc"
      @keyup="$emit('update:description', desc)"
    ></v-textarea>
  </div>
</template>

<script>
import trevXeroTenantAccountCodeSearch from "../../forms/trev-xero-tenant-account-code-search.vue";
export default {
  components: { trevXeroTenantAccountCodeSearch },
  props: {
    supplier: {
      type: String,
      default: "",
    },
    division: {
      type: String,
      default: "",
    },
    skuNumber: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    resourceType: {
      type: String,
      default: "",
    },
    xeroTenantId: {
      type: String,
      default: "",
    },
    xeroAccountCodeId: {
      type: String,
      default: "",
    },
  },
  watch: {
    supplier() {
      this.supp = this.supplier;
    },
    division() {
      this.div = this.division;
    },
    skuNumber() {
      this.sku = this.skuNumber;
    },
    name() {
      this.nam = this.name;
    },
    description() {
      this.desc = this.description;
    },
    resourceType() {
      this.resType = this.resourceType;
    },
    xeroAccountCodeId(){
      this.accCodeId = this.xeroAccountCodeId;
    },
    xeroTenantId(){
      this.tenantId = this.xeroTenantId;
    },
    accCodeId(newVal, oldVal){      
      this.$emit('update:xeroAccountCodeId', newVal);
    },
    tenantId(newVal, oldVal){
      this.$emit('update:xeroTenantId', newVal);
    }
  },
  mounted() {
    this.div = this.division;
    this.sku = this.skuNumber;
    this.nam = this.name;
    this.desc = this.description;
    this.supp = this.supplier;
    this.resType = this.resourceType;
    this.tenantId = this.xeroTenantId;
    this.accCodeId = this.xeroAccountCodeId;
  },
  data: () => ({
    div: "",
    sku: "",
    nam: "",
    desc: "",
    supp: "",
    resType: "",
    resourceTypes: ["Unlimited", "Time Bank", "Pay As You Go"],
    tenantId: '',
    accCodeId: ''
  }),
};
</script>

<style></style>
