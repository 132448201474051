<template>
  <div>
    <v-text-field
      label="Base Price"
      v-model="price2"
      type="number"
      prepend-icon="mdi-currency-gbp"
      @keyup="$emit('update:price', Number(price2))"
    ></v-text-field>
    <v-select
      label="Charge By"
      v-model="unitCharged2"
      :items="['Contact', 'Device', 'Item']"
      @change="$emit('update:unitCharged', unitCharged2)"
    >
    </v-select>
    <v-select
      label="Charge Frequency"
      v-model="chargeFrequency2"
      :items="['Monthly', 'Annually']"
      @change="$emit('update:chargeFrequency', chargeFrequency2)"
    >
    </v-select>
  </div>
</template>

<script>
export default {
    props: {
        price: {
            type: Number,
            default: 0
        },
        unitCharged: {
            type: String,
            default: ''
        },
        chargeFrequency:{
            type: String,
            default: ''
        }
    },
    watch: {
      price(){
        this.price2 = this.price;
      },
      unitCharged(){
        this.unitCharged2 = this.unitCharged;
      },
      chargeFrequency(){
        this.chargeFrequency2 = this.chargeFrequency;
      }
    },
    mounted(){
      this.price2 = this.price;
      this.unitCharged2 = this.unitCharged;
      this.chargeFrequency2 = this.chargeFrequency;
    },
    data: () => ({
        price2: 0,
        unitCharged2: '',
        chargeFrequency2: ''
    })
};
</script>

<style></style>
